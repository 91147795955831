// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-cms-templates-blog-post-tsx": () => import("/opt/build/repo/src/cms/templates/blog-post.tsx" /* webpackChunkName: "component---src-cms-templates-blog-post-tsx" */),
  "component---src-pages-about-tsx": () => import("/opt/build/repo/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("/opt/build/repo/src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-playlists-tsx": () => import("/opt/build/repo/src/pages/playlists.tsx" /* webpackChunkName: "component---src-pages-playlists-tsx" */),
  "component---src-pages-reviews-tsx": () => import("/opt/build/repo/src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

